export const MINUTE_IN_MILLISECONDS = 60000
export const WEEK_IN_MILLISECONDS = 604800000
export const DAY_IN_MILLISECONDS = 86400000
export const DOMINICA_TIMEZONE = 'America/Dominica'

export const SUNDAY = 'sunday'
export const MONDAY = 'monday'
export const TUESDAY = 'tuesday'
export const WEDNESDAY = 'wednesday'
export const THURSDAY = 'thursday'
export const FRIDAY = 'friday'
export const SATURDAY = 'saturday'

export const DAYS_OF_THE_WEEK = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY]
export const DAYS_OF_THE_WEEK_ORDER_MAP = {
    [SUNDAY]: 0,
    [MONDAY]: 1,
    [TUESDAY]: 2,
    [WEDNESDAY]: 3,
    [THURSDAY]: 4,
    [FRIDAY]: 5,
    [SATURDAY]: 6,
}

export const HOLIDAYS = {
    //2024 starts here, add the changing holidays there are 
    // carnival monday & tuesday, Good Friday, Easter Monday, May Day, Whit Monday, August Monday
    //first, the fixed holidays for 2024
    "2024-01-01": {
        "date": "2024-01-01",
        "name": "New Year's Day"
     },
     "2024-02-12": {
      "date": "2024-02-12",
      "name": "Carnival Monday"
      },
      "2024-02-13": {
         "date": "2024-02-13",
         "name": "Carnival Tuesday"
      },
      "2024-03-29": {
         "date": "2024-03-29",
         "name": "Good Friday"
      },
      "2024-04-01": {
         "date": "2024-04-01",
         "name": "Easter Monday"
      },
     "2024-05-06": {
        "date": "2024-05-06",
        "name": "May Day"
     },
     "2024-05-20": {
      "date": "2024-05-20",
      "name": "Whit Monday"
      },
      "2024-08-05": {
         "date": "2024-08-05",
         "name": "August Monday"
      },
     "2024-11-04": {
        "date": "2024-11-04",
        "name": "Independence Day"
     },
     "2024-11-05": {
        "date": "2024-11-05",
        "name": "National Day of Community Service"
     },
     "2024-12-25": {
        "date": "2024-12-25",
        "name": "Christmas Day"
     },
     "2024-12-26": {
        "date": "2024-12-26",
        "name": "Boxing Day"
     },
     //2025 starts here, add changing holidays
     // carnival monday & tuesday, Good Friday, Easter Monday, May Day, Whit Monday, August Monday
    //first, the fixed holidays for 2025
    // check this site in Jan 25: https://dominica.gov.dm/about-dominica/public-holidays
     "2025-01-01": {
      "date": "2025-01-01",
      "name": "New Year's Day"
      },
      "2025-03-03": {
         "date": "2025-03-03",
         "name": "Carnival Monday"
      },
      "2025-03-04": {
         "date": "2025-03-04",
         "name": "Carnival Tuesday"
      },
      "2025-04-18": {
         "date": "2025-04-18",
         "name": "Good Friday"
      },
      "2025-04-21": {
         "date": "2025-04-21",
         "name": "Easter Monday"
      },
      "2025-05-05": {
         "date": "2025-05-05",
         "name": "May Day"
      },
      "2025-06-09": {
         "date": "2025-06-09",
         "name": "Whit Monday"
      },
      "2025-08-04": {
         "date": "2025-08-04",
         "name": "August Monday"
      },
      "2025-11-03": {
         "date": "2025-11-03",
         "name": "Independence Day"
      },
      "2025-11-04": {
         "date": "2025-11-04",
         "name": "National Day of Community Service"
      },
      "2025-12-25": {
         "date": "2025-12-25",
         "name": "Christmas Day"
      },
      "2025-12-26": {
         "date": "2025-12-26",
         "name": "Boxing Day"
      },
      //2026 starts here, add changing holidays
     // carnival monday & tuesday, Good Friday, Easter Monday, May Day, Whit Monday, August Monday
    //first, the fixed holidays for 2026
    // check this site in Jan 25: https://dominica.gov.dm/about-dominica/public-holidays
     "2026-01-01": {
      "date": "2026-01-01",
      "name": "New Year's Day"
      },
      "2026-05-01": {
         "date": "2026-05-01",
         "name": "May Day"
      },
      "2026-11-03": {
         "date": "2026-11-03",
         "name": "Independence Day"
      },
      "2026-11-04": {
         "date": "2026-11-04",
         "name": "National Day of Community Service"
      },
      "2026-12-25": {
         "date": "2026-12-25",
         "name": "Christmas Day"
      },
      "2026-12-26": {
         "date": "2026-12-26",
         "name": "Boxing Day"
      },
 }

export const DAY_TIME_HOUR_LIST = [
   {id: "00:00", text: "12:00 AM"},
   {id: "00:30", text: "12:30 AM"},
   {id: "01:00", text: "1:00 AM"},
   {id: "01:30", text: "1:30 AM"},
   {id: "02:00", text: "2:00 AM"},
   {id: "02:30", text: "2:30 AM"},
   {id: "03:00", text: "3:00 AM"},
   {id: "03:30", text: "3:30 AM"},
   {id: "04:00", text: "4:00 AM"},
   {id: "04:30", text: "4:30 AM"},
   {id: "05:00", text: "5:00 AM"},
   {id: "05:30", text: "5:30 AM"},
   {id: "06:00", text: "6:00 AM"},
   {id: "06:30", text: "6:30 AM"},
   {id: "07:00", text: "7:00 AM"},
   {id: "07:30", text: "7:30 AM"},
   {id: "08:00", text: "8:00 AM"},
   {id: "08:30", text: "8:30 AM"},
   {id: "09:00", text: "9:00 AM"},
   {id: "09:30", text: "9:30 AM"},
   {id: "10:00", text: "10:00 AM"},
   {id: "10:30", text: "10:30 AM"},
   {id: "11:00", text: "11:00 AM"},
   {id: "11:30", text: "11:30 AM"},
   {id: "12:00", text: "12:00 PM"},
   {id: "12:30", text: "12:30 PM"},
   {id: "13:00", text: "1:00 PM"},
   {id: "13:30", text: "1:30 PM"},
   {id: "14:00", text: "2:00 PM"},
   {id: "14:30", text: "2:30 PM"},
   {id: "15:00", text: "3:00 PM"},
   {id: "15:30", text: "3:30 PM"},
   {id: "16:00", text: "4:00 PM"},
   {id: "16:30", text: "4:30 PM"},
   {id: "17:00", text: "5:00 PM"},
   {id: "17:30", text: "5:30 PM"},
   {id: "18:00", text: "6:00 PM"},
   {id: "18:30", text: "6:30 PM"},
   {id: "19:00", text: "7:00 PM"},
   {id: "19:30", text: "7:30 PM"},
   {id: "20:00", text: "8:00 PM"},
   {id: "20:30", text: "8:30 PM"},
   {id: "21:00", text: "9:00 PM"},
   {id: "21:30", text: "9:30 PM"},
   {id: "22:00", text: "10:00 PM"},
   {id: "22:30", text: "10:30 PM"},
   {id: "23:00", text: "11:00 PM"},
   {id: "23:30", text: "11:30 PM"},
]